@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
body {font-family: "Poppins", sans-serif !important; font-weight: 500;}
a{
  text-decoration: none !important;font-family: "Poppins", sans-serif !important; 
}
p{font-family: "Poppins", sans-serif !important; }
h1, h2, h3, h4, h5, h6 {font-family: "Poppins", sans-serif !important; }


.navi a  {font-family: "Inter", sans-serif !important; font-weight: 500;font-style: normal;}
.navi a {
    font-size: 15px;
    font-weight: 500;
    line-height: 18.15px;
    text-align: left;
    padding: 0px 17px 20px 17px !important;
    color: #C5C4C4;
  }
  .he_ade_r nav {
    padding-top: 0 !important; padding-bottom: 0px !important;
  }
  nav {
    height: 90px !important; background: #010a01 !important;
  }
  .navi { padding:9px 12px 0 12px;}
  .navi a {
    font-size: 15px;
    font-weight: 500;
    line-height: 18.15px;
    text-align: left;
    padding: 0px 5px 20px 5px !important;
    position: relative;
    background: linear-gradient(-55.53deg,#14ae5c 50%,#3ce68b70 68%);
      background-clip: border-box;
    background-clip: border-box;
    background-clip: border-box;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .navi a:hover {background: linear-gradient(-55.53deg,#14ae5c 50%,#3ce68b70 68%);
      background-clip: border-box;
    background-clip: border-box;
    background-clip: border-box;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .navi a.active{
    background: linear-gradient(272.76deg, #29F069 2.3%, #E7EC03 94.31%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .navi a.active::after {position: absolute; content: ""; height: 2px; width: 100%; background: linear-gradient(272.76deg, #29F069 2.3%, #E7EC03 94.31%);
   box-shadow: 0px -7px 12px 5px #14ae5cab;bottom: 0;}
   .metas {background: #040c03 !important;border: 1px solid #152f17 !important;
    border-radius: 20px !important;font-family: "Inter", sans-serif !important; color:#fff !important;}
  
  .metas svg:first-child {
    background: #212a21;
    padding: 3px;
    border-radius: 50%;
    height: 24px !important;
    width: 24px !important;
  }
  .metas span{ color: #fff !important;font-family: "Inter", sans-serif !important;}
  .conn_e{background: #040c03 !important; color:#fff !important; 
    border: 1px solid #152f17 !important;border-radius: 20px !important;font-family: "Inter", sans-serif !important;}
  .app_dnld{ display: none !important;}

  .he_ade_r > div {
    border: none !important;font-family: "Inter", sans-serif !important;
  }
  .selec_tt h6 {font-family: "Inter", sans-serif !important;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    text-align: right;margin: 15px 0 0 0;
  }
  .selec_tt h6 span{ color:#1bab49 !important;font-family: "Inter", sans-serif !important;}
  /* footer {
    background: url(/./Interface/apps/web/public/images/footer.png);
  } */
/* .hgWmWh{
  background: transparent !important;
}
.hybkVM{
  padding-bottom: 0px !important;
} */
.Footer {font-family: "Poppins", sans-serif !important;
  width: 100%;
  min-height: 295px; background-size: 100% 100%;  
  background: url(../../../public/images/footer.png);
  background-size: cover !important;margin-top: -65px;
} 

.inner-footer {
  width: calc(100% - 40px);
  max-width: 1280px;
  display: grid;
  grid-template-columns: 220px auto;
  gap: 40px;
  padding: 100px 0px; margin: auto;
} 
.footer_con {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr; grid-gap: 20px;
}
.ft1{
  display: flex;
  flex-direction: column;
  gap: 32px;
}
/* .footer_con {
  display: flex;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 20px;
  justify-content: center;
  flex-wrap: wrap;
} */
.social {
  display: flex;
  justify-content: center;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
}
.social a svg{padding: 0 20px;
  font-size: 32px;
}
.social a:hover{
  background: linear-gradient(-55.53deg,#14ae5c 50%,#3ce68b70 68%);
}

.heding h2{
  font-family: "Poppins";
  font-size: 15px;
  font-weight: 600;
  line-height: 22.5px;
  color: #FFFFFF;font-family: "Poppins", sans-serif !important;
}
.Text {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.Text a{
  font-family: "Poppins";
  font-size: 13px;
  font-weight: 400;
  line-height: 19.5px;
  color: #FFFFFF60;font-family: "Poppins", sans-serif !important;
}
.Text a:hover{
  color: #FFFFFF;
}
.ftbtn{
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.ftbtn a{
  border: 1px solid #ffffff24;
  background: linear-gradient(202.48deg, #09180A 2.32%, #050B05 63.01%);
  box-shadow: -4px 4px 4px 0px #091C0340;
  border-radius: 8px;
  display: flex;
  align-items: center;
  gap: 12px;
  justify-content: space-evenly;
  padding: 10px 25px;
  color: #FFFFFF;
  max-width: 131px;
  min-height: 43px;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
}
.ftbtn a span{
  font-size: 16px;
  font-weight: 600;
}
.ftbtn a:hover{
  background: linear-gradient(202.48deg,#050B05 0A 2.32%, #09180A 63.01%);
  opacity: 0.7;
}
.ftbtn a span img{
  background-color: #FFAF1D;
  border-radius: 50px;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  object-fit: contain;
}


/* Model css  */
.igqudN{
  background: linear-gradient(202.48deg, #09180A 2.32%, #050B05 63.01%) !important;
  border: 1px solid rgba(255, 255, 255, 0.1) !important;
  box-shadow: -4px 4px 4px 0px #091C0340 !important;
  border-radius: 8px !important;
    
}
.lis_tt_found {
  max-height: 200px;
  overflow: auto;
}
.curr > div {
  background: linear-gradient(202.48deg, #09180A 2.32%, #050B05 63.01%);
  border: 1px solid #1C371EA3; 
  color: #fff !important;font-family: "Poppins", sans-serif !important; width: 99.5%;

}
body .curr > div svg {
  color: #fff;
}

.curr #token-search-input {
  background-color: #101c10;
  padding-top: 4px;
  padding-bottom: 4px;
  border: 0.8px solid #FFFFFF1A;
}
.lis_tt > div > div {
  background: #FFFFFF08;
  border: 0.8px solid #FFFFFF1A;
  color: #fff !important;
}
.bit_row {
  display: flex;
  justify-content: space-between;
  gap: 15px; align-items: center;
}
.bit_row h2 {
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  text-align: right;margin: 0 0 15px 0;
  display: flex;
  align-content: center;
  align-items: center;
  gap: 11px;
}
.bit_row h2 span {
  display: grid;
  text-align: left;
}
.bit_row h2 span strong {
  font-weight: 400;
}
.bit_row.yell h2 span strong {
  color: #ffb121;
}

.bit_row.blu h2 span strong {
  color: #96ace0;
}
.bit_row.pnk h2 span strong {
  color: #ffa5ee;
}

.bit_row.grn h2 span strong {
  color: #b7ffe9;
}

.bit_row h3 {
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;display: grid;
}
.bit_row h3 i {
  font-style: normal;
  color: #FFFFFF99;
}
.social a {
  display: flex;
  background: #a5a6a524;
  border-radius: 50%;
  height: 34px;
  width: 34px;
  align-items: center;
}
.social { display: flex; justify-content: center; align-items: center;padding: 0;gap: 5px;}
.ft1 img {width: 150px;margin: auto;}
/* .wall_et {
  width: calc(100% - 31px);
} */
.wallet_dv {
  right: -2px;
  position: relative;
}
.body_wrap {
  padding-bottom: 0px !important;
}

.wallet_dv {
  background: transparent !important;
}
.wallet_dv #wallet-dropdown-scroll-wrapper {
  background: transparent !important;
}
.wallet_dv #wallet-dropdown-scroll-wrapper > div > div {
 
  background: linear-gradient(202.48deg, #09180A 2.32%, #050B05 63.01%); color:#fff !important;
  border: 1px solid #152f17;
}
.wallet_header {
  color: #fff !important;
}
.wall_des {
  padding-bottom: 20px !important;
  padding-top: 10px !important;
}
.wallet_style {
  background: #070f07 !important;
  border: 1px solid #FFFFFF26 !important;
  width: 99.5% !important;
  border-radius: 16px;
  margin-bottom: 13px;
  color: #fff !important;
}
.wallet_style:hover {
  background: #0b1a0b !important;
  border: 1px solid #152f17 !important;
}
.wallet_name {
  color: #fff !important;
}
.wallet_footer {
  color: #FFFCFC80;
  font-size: 11px !important;
}
.wallet_footer a {
  color: #29f069;
}
.wallet_connec_t {
  display: flex;
  background: linear-gradient(90.47deg, #E7EC03 -24.18%, #29F069 70.82%);
  color: #032905 !important;
  font-size: 14px !important;
  padding: 9px 13px;
  height: 45px;
  border-radius: 12px;
  align-items: center;
  justify-content: center;
  font-weight: 600 !important;
  margin-top: 30px;
  margin-bottom: 15px;
}
.con_c {
  font-size: 12px;
  text-align: center;
}
.liqui_d {
  padding: 6px 30px 30px 30px !important;
  border-radius: 24px !important;
  background: #081308 !important;
  margin-top: 80px !important;width: auto !important;

}
.sett_in_g {background: #FFFFFF1A;border-radius: 5px !important;padding: 6px 6px !important;}
.liqui_d .btn_sty button{
background-color: #101c11 !important;
  border: 1px solid #152f17 !important;
}
.liqui_d button > div{ color: #fff !important;}
.btn_sty #add-liquidity-selected-fee > div {
  color: #fff !important;
  opacity: 1 !important;
}
.new_check > div {
  background: transparent;
  border: 2px solid #29f069;
}
.liqid_row {
  background: #0b180c !important;
  border: 1px solid #152f17 !important;
}
.liqui_d .btn_sty button.range_btn {
  padding: 0 11px;
}
.swap_input #add-liquidity-input-tokena > div {
  background: #0b180c !important;
  border: 1px solid #152f17 !important;
}
.swap_input #add-liquidity-input-tokenb > div {
  background: #0b180c !important;
  border: 1px solid #152f17 !important;
}
.swap_input {
  text-align: center;
}
.swap_img {
  width: 41px !important;
  margin: -16px auto;
  position: relative;
  z-index: 5;
}
.sub_menu {background: #0b180c !important;border: 1px solid #152f17 !important;left: -46px;
top: 58px !important; width: 120px !important; padding: 0px !important;border-radius:8px !important ;}
.sub_menu a {
  color: #fff !important;
  -webkit-text-fill-color: #fff !important;
  background: linear-gradient(180deg, #1B1D1A 0%, #091309 100%), linear-gradient(229.29deg, rgba(158, 255, 0, 0.15) -68.25%, rgba(158, 255, 0, 0) 32.16%);
  padding: 10px 14px !important;
}
.sub_menu > div > div {
  gap: 0px;
}

.liqui_d .btn_sty .connect_b_t {
  background-color: #1e271e !important; color: #ffffffb8 !important;
  border: 1px solid #152f17 !important; height: 55px !important;
}
.home_grd{  background: url(../../../public/images/home_bg.jpg);width: 100%;
  background-size: cover;padding-top: 100px !important;min-height: calc(100vh - 116px);
  height: auto;
}

  .home_grd .grd_inner{display: grid; grid-template-columns: 1fr 1fr; grid-gap: 20px;
      background: transparent !important;max-width: 1400px; margin: auto; 
      padding: 0 20px !important;align-content: center;
      align-items: baseline;width: calc(100% - 40px);
    }
    .her_o > div {
      background: transparent;
      padding-top: 0px !important;
      padding-bottom: 20px !important;}
      .home_grd .grd_inner h3 {
        font-size: 31.6px;
        font-weight: 500;
        line-height: 37.03px;
        letter-spacing: -0.04em;
        text-align: left;margin: 15px 0; color: #fff !important;
      
      } 
      .home_grd .grd_inner h3 span {color: #29F069 !important;}
      .home_grd .grd_inner h2 {
        font-size: 48px; margin: 0px; padding-bottom: 35px; color: #fff !important;
      }
      .hero_social {
        display: flex;
        justify-content: center;
        gap: 10px;
        width: 473px;
        word-break: unset;
        align-items: center;
      }
      .hero_social p {
        color: #fff !important;
      }
      .conn_bt a {
        background: linear-gradient(90.47deg, #E7EC03 -24.18%, #29F069 70.82%);
        color: #000;
        font-weight: 600;
        padding: 15px 20px;
        width: 100%;
        max-width: 400px;
        border-radius: 8px;
        margin-top: 50px;
        text-align: center;
        height: 45px;
        font-size: 18px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .home_grd .main_swap h2 {
        font-size: 12px !important;
        font-weight: 600;
        line-height: 18px;
        text-align: left;
        padding-bottom: 10px !important;
        color: #FFFFFFB2;
      }
      .home_grd .swap_det {
        display: flex;
        justify-content: space-between;
        gap: 20px;
        align-items: center;
      }
      .home_grd .swap_det p {
        color: #FFFFFFB2; font-size: 12px !important;
      }
      .home_grd .swap_det h4 {
        color: #FFFFFFB2;
        font-size: 12px;
        font-weight: 500 !important;
        font-family: "Poppins", sans-serif !important;
      }
      .home_grd .swap_det.dn p, .home_grd .swap_det.dn h4 {
        margin:10px 0 !important;
      }
      .swap_gap {
        padding: 20px 0 15px 0;
      }

      .swap44 {
        max-width: 600px !important; margin-top: 120px;
        background: radial-gradient(119.65% 130.39% at 98.37% 0.84%, rgba(97, 152, 96, 0.0525) 0%, rgba(76, 109, 75, 0.095) 100%) !important;
        padding: 30px !important;
        border-radius: 20px !important;
      }
.swap44 .swap_det, .swap44 h2 {display: none; }
.swap_menu {border: 1px solid #152f17 !important; background: #0b180c !important;}
.swap_menu button span { background: #1bab49; }
.swap_menu button span:hover { background: #1bab49 !important;}
.swap_menu button  { background: #101c11;}
.swap_menu a > div  { color: #1bab49;  }
body .popup_styl > div {min-height: 50vh !important;}
.main_pool { background: radial-gradient(119.65% 130.39% at 98.37% 0.84%, rgba(97, 152, 96, 0.0525) 0%, rgba(76, 109, 75, 0.015) 100%) !important;
padding: 30px !important; border-radius: 20px !important;}
#join-pool-button {background-color: rgb(16, 28, 17) !important;
border: 1px solid rgb(21, 47, 23) !important;}

#join-pool-button:active {
  box-shadow: 0 0 0 1pt rgb(16, 28, 17) !important;}

.btns_sty{background-color: rgb(16, 28, 17) !important;
  border: 1px solid rgb(21, 47, 23) !important;}
.link-cols a{background-color: rgb(16, 28, 17) !important;
  border: 1px solid rgb(21, 47, 23) !important;}
.menu_sect{background-color: rgb(16, 28, 17) !important;
  border: 1px solid rgb(21, 47, 23) !important;}
.btns_sty.p_add {padding: 20px;border-radius: 20px;margin-top: 92px !important; }
.btns_sty.p_add a{background-color: rgb(16, 28, 17) !important;
  border: 1px solid rgb(21, 47, 23) !important; color:#fff !important}
.colWhite { color: #fff !important;}
.swap44.p_add {margin-top: 92px !important; }
button:active, button:focus{ box-shadow: none !important;}
.swap_height {min-height: 100px !important;}
.swap_page .selec_tt h6 {display: none;}
.swap_page .arr_r svg {display: none;}
.arr_r {padding: 5px 0;}
.aftr_connect {background: linear-gradient(90.47deg, #E7EC03 -24.18%, #29F069 70.82%);color: #000 !important;}
.swap_page {width: 80% !important;}
.btns_sty.col_pn div {color: #fff !important;}
.btns_sty.col_pn div svg path { fill: #fff !important;}
.swap44.swap_page {margin-bottom: 60px;}
.swap44.swap_page h3 {color: #fff;}

.main_swap .btns_sty {color: #fff !important;}
.wallet_style button div {color: #fff;background: transparent;}
.drp_menu {border-radius: 16px;font-size: 14px;background: #0b180c !important;
  border: 1px solid #152f17 !important;}
.drp_menu button div {color: #fff !important;}
.drp_menu button svg {color: #fff;}
.more_menus{border-radius: 16px;background: #0b180c !important;
  border: 1px solid #152f17 !important;}
.drp_menu div {color: #fff;}
.drp_menu div a:hover {color: #fff !important;}
.drp_menu svg {color: #fff !important;fill: #fff;}
.social img {
  filter: brightness(0) invert(1);
  width: 30px;
  height: 30px;
}
.btns_sty span {
  color: #fff;
}
.app_btn {
  display: none;
}
.main_swap {
  display: block !important;
}
.col_white {
  color: #fff;
}
/* responsive */
.btns_new > button{
  background: #040c03 !important;
  border: 1px solid #152f17 !important;
  border-radius: 20px !important;
  font-family: "Inter", sans-serif !important;
}

.swap-nw input {
  color: #fff;
}
.test1 div {
  color: #fff;
}
.test1 div a{
  color: rgb(64, 182, 107) !important; background: transparent;
}
.max_col{color: rgb(64, 182, 107) !important;}
@media screen and (max-width: 1400px) {
.swap_block > div {
  width: 100%;
  max-width: 540px;
  margin-top: 0 !important;
}
}

@media screen and (max-width: 991px) {
  .inner-footer {grid-template-columns: auto;justify-content: center;}
  .inner-footer .ft1 img { margin: auto;}
  .home_grd .grd_inner h2 { font-size: 35px;}
  .home_grd .grd_inner {grid-template-columns: auto;}
  .home_grd .grd_inner { justify-content: center;}
  .conn_bt a {width: 200px;margin-top: 10px;height: 25px;}
  .hero_social { justify-content: left; width: 100%;flex-wrap: wrap;}

}
@media screen and (max-width: 768px) {
  .navi {padding: 9px 2px 0 2px;}
  .ggdt { flex-wrap: wrap !important;display: flex !important;}
  .hero_social img { width: 21px;}
}
@media screen and (max-width: 600px) {
.footer_con { display: flex;justify-content: center; flex-wrap: wrap;}
.log_go {width: 90px !important;}
.metas span {font-size: 13px !important;}
.conn_e { font-size: 13px !important; height: 45px !important;}
.metas svg:first-child { padding: 2px;height: 25px !important;width: 25px !important;}
.metas {padding: 8px 6px !important;height: 45px;}
.selec_tt button span {font-size: 15px !important;}
.home_grd .grd_inner h2 {font-size: 30px;}
.home_grd .grd_inner h2 br { display: none;}
}
@media screen and (max-width: 380px) {

  .footer_con { display: grid;justify-content: center; grid-template-columns: auto; }
  }
